<template>
  <div class="admin-wizard">
    <AdminHeader />
      <AdminBreadcrumb
     @on-saveContinue="onFormSubmit(true)"
     />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <b-card>
              <b-card-body class="p-0">
                <ValidationObserver ref="observer">
                  <b-form @submit.prevent="onFormSubmit">
                    <b-card-title
                      >Edit your event.
                    </b-card-title>
                    <div class="guide-tip">
                      <b-row align-v="center">
                        <b-col cols="3" lg="1" class="icon">
                          <font-awesome-icon :icon="['far', 'calendar-alt']" />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="8"
                          order="3"
                          order-lg="2"
                          class="text"
                        >
                          Need to make changes to an event you have already
                          created, cancel an event, or create a new event? You can
                          do that here.
                        </b-col>
                        <b-col
                          cols="9"
                          lg="3"
                          order="2"
                          order-lg="3"
                          class="button"
                        >
                          <b-button
                            variant="outline-secondary"
                            size="sm"
                            v-b-modal.modal-theme-guide
                          >
                            View Example
                            <font-awesome-icon
                              :icon="['far', 'window-maximize']"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>                    
                    <b-alert :show="!!errorMessage" variant="danger">{{
                      errorMessage
                    }}</b-alert>
                    <b-form-group
                      label="Event Title"
                      label-class="font-weight-bold"
                      label-for="input-event-title"
                    >
                      <ValidationProvider
                        name="Event Title"
                        rules="required"
                        v-slot="validationContext"
                      >
                        <b-form-input
                          id="input-event-title"
                          v-model="form.title"
                          :state="getValidationState(validationContext)"
                          placeholder="Enter your event title"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-row>
                      <b-form-group
                        label="Event Date"
                        label-class="font-weight-bold"
                        label-for="input-event-date"
                        class="col-md-6"
                      >
                        <ValidationProvider
                          name="Event Date"
                          rules="required"
                          v-slot="validationContext"
                        >
                          <b-form-datepicker
                            placeholder="Select date"
                            id="event-date"
                            v-model="form.date"
                            :state="getValidationState(validationContext)"
                          ></b-form-datepicker>
                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group
                        label="Event Time"
                        label-class="font-weight-bold"
                        label-for="input-event-time"
                        class="col-md-6"
                      >
                        <ValidationProvider
                          name="Event Time"
                          rules="required"
                          v-slot="validationContext"
                        >
                          <b-form-timepicker
                            v-model="form.time"
                            :state="getValidationState(validationContext)"
                            placeholder="Enter your event time"
                          ></b-form-timepicker>
                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-form-row>
                    <b-form-row>
                      <b-form-group
                        label="Event Description"
                        label-class="font-weight-bold"
                        label-for="input-event-time"
                        class="col-md-12"
                      >
                        <ValidationProvider
                          name="Event Description"
                          rules=""
                          v-slot="validationContext"
                        >
                          <b-form-textarea
                            id="event-description"
                            v-model="form.description"
                            :state="getValidationState(validationContext)"
                            placeholder="Enter your event descripotion"
                            rows="4"
                            max-rows="6"
                            @change="onOptionChange($event, form.coverPhoto)"
                          ></b-form-textarea>
                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-form-row>
                    <b-form-row>
                      <b-form-group
                        label="Button Text"
                        label-class="font-weight-bold"
                        label-for="input-ctatext"
                        class="col-md-6"
                      >
                        <ValidationProvider
                          name="Button Text"
                          v-slot="validationContext"
                           :rules="form.ctaLink ? 'required' : ''"
                        >
                          <b-form-input
                            id="input-ctatext"
                            v-model="form.ctaText"
                            :state="getValidationState(validationContext)"
                            placeholder="Ex: RSVP"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group
                        label="Button Link"
                        label-class="font-weight-bold"
                        label-for="input-ctalink"
                        class="col-md-6"
                      >
                        <ValidationProvider
                          name="Button Link"
                          v-slot="validationContext"
                          :rules="form.ctaText ? 'required|url' : 'url'"
                        >
                          <b-form-input
                            id="input-ctalink"
                            v-model="form.ctaLink"
                            :state="getValidationState(validationContext)"
                            placeholder="Ex: https://www.eventbrite.com/event"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </b-form-row>
                    <b-form-row>
                      <b-form-group
                        label="Cover Photo"
                        label-class="font-weight-bold"
                        label-for="input-photo"
                        class="col-md-6"
                      >
                        <ValidationProvider
                          name="Cover Photo"
                          v-slot="validationContext"
                        >
                          <b-form-file
                            v-model="form.coverPhoto"
                            :state="getValidationState(validationContext)"
                            accept="image/*"
                            placeholder="Select a photo"
                          ></b-form-file>
                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>
                        <b-img
                          fluid
                          v-model="form.existingPhotoUrl"
                          :src="form.existingPhotoUrl"
                        ></b-img>
                      </b-form-group>
                      <!-- <b-form-group
                        label="Status"
                        label-class="font-weight-bold"
                        label-for="input-status"
                        class="col-md-6"
                      >
                        <ValidationProvider
                          name="Status"
                          rules="required"
                          v-slot="validationContext"
                        >
                          <b-form-select
                            v-model="form.status"
                            :options="listOfStatus"
                            :state="getValidationState(validationContext)"
                          ></b-form-select>
                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group> -->
                    </b-form-row>
                    <b-button
                      v-show="false"
                      ref="submit"
                      type="submit"
                      variant="primary"
                      >Submit</b-button
                    >
                  </b-form>
                </ValidationObserver>
              </b-card-body>
            </b-card>
            <AdminFooterButton
              @on-save="onSave"
              @on-back="onBack"
              @on-preview="onPreview"
              @on-skip="onSkip"
              :saving="saving"
              :invalid="false"
            />
          </b-col>
        </b-row>
      </b-container>
    </main>
    <ModalThemeGuide
      theme-id="events"
      :template-id="candidateModel.profileTheme"
    />
  </div>
</template>

<script>
import DirtyFlagMixin from "@/mixins/dirtyflag.mixin";
import CandidateMixin from "@/mixins/candidate.mixin";
export default {
  name: "AddEvent",
  mixins: [DirtyFlagMixin, CandidateMixin],
  data() {
    return {
      form: {
        title: null,
        date: null,
        time: null,
        description: null,
        ctaText: null,
        ctaLink: null,
        status: null,
        coverPhoto: null,
        visible: true,
        existingPhotoUrl: null,
        id: null
      },
      listOfStatus: [
        { value: null, text: "Please select status" },
        { value: "Upcoming", text: "Upcoming" },
        { value: "Past", text: "Past" }
      ],

      loading: false,
      errorMessage: null
    };
  },
  created() {
    this.onEdit();
    //this.init();
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async init() {
      this.loading = true;
      try {
        if (this.$store.state.candidates.candidate == null) {
          await this.$store.dispatch(
            "candidates/getCandidate",
            this.$route.params.id
          );
        }
        this.loading = false;
        //this.form.name = this.$store.state.candidates.candidate.name;
      } catch (error) {
        this.loading = false;
        this.errorMessage = error;
      }
    },
    onOptionChange(e, a) {},
    onEdit() {
      let eventId = this.$route.params.eventId;

      if (eventId) {
        let event = this.$store.state.candidates.candidate.events.filter(
          function(evt) {
            return evt.id == eventId;
          }
        );
        event = event[0];
        let date = event.dateTimeText.split(" ");
        this.form.title = event.title;
        this.form.date = new Date(date[0]).toISOString().split("T")[0];
        this.form.time = date[1];
        this.form.description = event.description;
        this.form.ctaText = event.ctaText;
        this.form.ctaLink = event.ctaLink;
        this.form.id = event.id;
        this.form.existingPhotoUrl = event.coverPhotoUrl;
      } else {
        this.form = [];
      }
    },
    onPreview() {},
    onBack() {
      this.$router.push("manage-media");
    },
    onSkip() {
      this.$router.push("manage-events");
    },
    onSave() {
      this.$refs.submit.click();
    },
    async onFormSubmit(fromBreadcrumb) {
      this.loading = true;
      this.isSubmitting = true;
      this.saving = true;
      try {
        this.form.userId = this.$route.params.id;
        const eventDate = new Date(this.form.date);
        if (eventDate > Date.now()) this.form.status = "Upcoming";
        else this.form.status = "Past";
        await this.$store.dispatch("candidates/saveEvent", this.form);

        setTimeout(() => {
          this.saving = false;
          if(fromBreadcrumb != true){
            this.$router.push("manage-events");
          }
          
        }, 500);
      } catch (error) {
        this.loading = false;
        this.saving = false;
        this.errorMessage = error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";
</style>
